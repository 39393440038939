<template>
  <div class="reinvestment-wrapper reinvestment-centered-wrapper">
    <div class="reinvestment-content">
      <div class="reinvestment-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>

      <i18n
        path="reinvestment.selectDestinationFund.fromFund"
        tag="p"
        class="reinvest-from-source-fund"
      >
        <template v-slot:fundName>
          <span> {{ reinvestment.originFund.fundRun | fundShortName }}</span>
          <div class="inline-wrapper">
            <div
              :style="{ backgroundColor: originFundData.color }"
              class="fund-icon-container center-in-page"
            >
              <v-icon color="#fff" size="12"> mdi-{{ originFundData.icon }}</v-icon>
            </div>
          </div>
        </template>
      </i18n>

      <h2 class="reinvestment-header">
        {{ $i18n.t("reinvestment.selectDestinationFund.title") }}
      </h2>

      <div class="customer-funds">
        <div
          v-for="(fundBalance, index) in formattedFundsBalances"
          :key="index"
          @click="selectFund(fundBalance.fundRun)"
          class="fund-balance"
        >
          <BalanceDetailsFundCard :fundBalance="fundBalance" class="fund-balance-card" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import funds from "@/mixins/funds.js";
import BalanceDetailsFundCard from "@/components/results/BalanceDetailsFundCard.vue";

export default {
  name: "SelectDestinationFund",

  components: {
    BalanceDetailsFundCard,
  },

  mixins: [funds],

  computed: {
    ...mapGetters(["reinvestment", "funds", "fundsBalances", "otherFunds"]),

    originFundData() {
      const staticData = this.getFundStaticData(this.reinvestment.originFund.fundRun);
      // Validate if the staticData is available before returning the icon and color
      return {
        icon: staticData?.icon,
        color: staticData?.color,
      };
    },

    formattedFundsBalances() {
      const allFunds = this.funds.concat(this.otherFunds);
      return allFunds
        .map(({ run, ...fund }) => {
          // Retrieve the balance for the current fund or default to an empty object if not found
          const fundBalance = this.fundsBalances[run] || {};

          return {
            ...fund,
            fundRun: run.toString(), // Ensure fundRun is a string
            fundCurrent: fundBalance.fundCurrent ?? 0,
          };
        })
        .filter(({ fundRun }) => fundRun !== this.reinvestment.originFund.fundRun)
        .sort((a, b) => b.fundCurrent - a.fundCurrent) // Sort by fundCurrent in descending order
        .map(({ fundRun, fundCurrent }) => ({
          currencyAmount: fundCurrent,
          fundRun,
        }));
    },
  },

  methods: {
    ...mapActions(["setReinvestmentData"]),

    selectFund(fundRun) {
      let fundSeriesId;

      // In first place, try to get the fundSeriesId from the customer balances
      fundSeriesId = this.fundsBalances[fundRun]?.fundSeriesDetails?.fundSeriesId;

      if (!fundSeriesId) {
        // If not found, fallback to the static data and get the fundSeriesId from the retailSeriesNemo
        const { retailSeriesNemo } = this.getFundStaticData(fundRun);
        const otherFund = this.otherFunds.find(fund => fund.run.toString() === fundRun);

        fundSeriesId = otherFund?.fundSeries.find(
          fundSeries => fundSeries.symbol === retailSeriesNemo,
        )?.id;
      }

      // Set reinvestment data and navigates to the next step
      this.setReinvestmentData({
        ...this.reinvestment,
        destinationFund: { fundSeriesId, fundRun },
      });
      this.$router.push({ name: "chooseReinvestmentAmount" });
    },
  },
};
</script>

<style lang="scss" scoped>
.fund-balance + .fund-balance {
  margin-top: 10px;
}

.fund-balance {
  max-width: 420px;
}

.fund-balance-card {
  &:hover {
    cursor: pointer;
    background-color: #fafafa;
  }
}

.reinvest-from-source-fund {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}

.fund-icon-container {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.inline-wrapper {
  display: inline-block;
  margin-left: 5px;
}

::v-deep .balance-details-fund-card {
  border: none !important;
}
</style>
