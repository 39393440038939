/* eslint-disable no-param-reassign */
/* eslint-disable import/extensions */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
// eslint-disable-next-line import/no-unresolved

import dayjs from "dayjs";
import isboolean from "lodash.isboolean";
import Vue from "vue";

import fundsMixin from "@/mixins/funds.js";
import { bankSelects } from "@/mixins/bankSelects.js";
import { get } from "../utils/vuex.js";

const actions = {
  setAuthenticatedStatus({ commit }, payload) {
    commit("setAuthenticatedStatus", payload);
  },

  setFundsData({ commit }, payload) {
    commit("setFundsData", payload);
  },

  setMovementsData({ commit }, payload) {
    commit("setMovementsData", payload);
  },

  setBalanceHistoryData({ commit }, payload) {
    commit("setBalanceHistoryData", payload);
  },

  // Includes exchangeRate, quotaValue.
  setFundsDetailedData({ commit }, payload) {
    commit("setFundsDetailedData", payload);
  },

  setProfileData({ commit }, payload) {
    commit("setProfileData", payload);
  },

  setBalanceDetails({ commit }, payload) {
    commit("setBalanceDetails", payload);
  },

  setTotalResults({ commit }, payload) {
    commit("setTotalResults", payload);
  },

  setCurrency({ commit }, payload) {
    commit("setCurrency", payload);
  },

  setProfile({ commit }, payload) {
    commit("setProfile", payload);
  },

  setLoading({ commit }, payload) {
    commit("setLoading", payload);
  },

  setLoadingBalanceHistory({ commit }, payload) {
    commit("setLoadingBalanceHistory", payload);
  },

  setLoadingProfile({ commit }, payload) {
    commit("setLoadingProfile", payload);
  },

  setLoadingPendingMovements({ commit }, payload) {
    commit("setLoadingPendingMovements", payload);
  },

  setLoadingMovements({ commit }, payload) {
    commit("setLoadingMovements", payload);
  },

  setLoadingReinvestments({ commit }, payload) {
    commit("setLoadingReinvestments", payload);
  },

  cleanStore({ commit }, payload) {
    commit("cleanStore", payload);
  },

  setCustomerChoices({ commit }, payload) {
    commit("setCustomerChoices", payload);
  },

  setPendingMovementsData({ commit }, payload) {
    commit("setPendingMovementsData", payload);
  },

  setReinvestmentsData({ commit }, payload) {
    commit("setReinvestmentsData", payload);
  },

  setFintocStatus({ commit }, payload) {
    commit("setFintocStatus", payload);
  },

  setUserData({ commit }, payload) {
    commit("setUserData", payload);
  },

  setQueryParams({ commit }) {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    const referralParam = params?.referral;
    const emailParam = params?.email;
    const targetFundParam = params?.fundRun;
    const utmSourceParam = params?.utm_source;
    const utmContentParam = params?.utm_content;
    const utmCampaignParam = params?.utm_campaign;

    const utmParams = { utmSourceParam, utmContentParam, utmCampaignParam };

    if (targetFundParam) {
      commit("setTargetFund", targetFundParam);
    }

    if (emailParam) {
      commit("setProposedEmail", emailParam);
    }

    if (referralParam) {
      commit("setReferralSource", referralParam);
    }

    if (Object.values(utmParams).some(param => param)) {
      commit("setUtmParams", utmParams);
    }
  },

  deleteParams({ commit }) {
    window.history.replaceState({}, document.title, "/");
    commit("setTargetFund", null);
    commit("setReferralSource", null);
  },

  setTargetFund({ commit }, payload) {
    commit("setTargetFund", payload);
  },

  setRedemptionData({ commit }, payload) {
    commit("setRedemptionData", payload);
  },

  setReinvestmentData({ commit }, payload) {
    commit("setReinvestmentData", payload);
  },

  setSignupStatus({ commit }, payload) {
    commit("setSignupStatus", payload);
  },

  setProfilingCustomerAnswer({ commit }, payload) {
    commit("setProfilingCustomerAnswer", payload);
  },

  setManualProfilingCustomerAnswer({ commit }, payload) {
    commit("setManualProfilingCustomerAnswer", payload);
  },

  setProfilingCustomerReferralAnswer({ commit }, payload) {
    commit("setProfilingCustomerReferralAnswer", payload);
  },

  setProfilingCustomerLaborData({ commit }, payload) {
    commit("setProfilingCustomerLaborData", payload);
  },

  setProfilingCustomerCapitalOrigin({ commit }, payload) {
    commit("setProfilingCustomerCapitalOrigin", payload);
  },

  setProfilingCustomerNetWorth({ commit }, payload) {
    commit("setProfilingCustomerNetWorth", payload);
  },

  setProfilingCustomerSalaryRange({ commit }, payload) {
    commit("setProfilingCustomerSalaryRange", payload);
  },

  setProfilingCustomerDeclarations({ commit }, payload) {
    commit("setProfilingCustomerDeclarations", payload);
  },

  setContractReinvestDividends({ commit }, payload) {
    commit("setContractReinvestDividends", payload);
  },

  setIdDocumentImages({ commit }, payload) {
    commit("setIdentificationDocumentImages", payload);
  },

  setIdentifier({ commit }, payload) {
    commit("setIdentifier", payload);
  },

  setPersonal({ commit }, payload) {
    commit("setPersonal", payload);
  },

  setSpouse({ commit }, payload) {
    commit("setSpouse", payload);
  },

  setConjugalRegime({ commit }, payload) {
    commit("setConjugalRegime", payload);
  },

  setContactData({ commit }, payload) {
    commit("setContactData", payload);
  },

  setInvestData({ commit }, payload) {
    commit("setInvestData", payload);
  },

  setDividendDeclarationAnswer({ commit }, payload) {
    commit("setDividendDeclarationAnswer", payload);
  },

  setCustomerDividendsData({ commit }, payload) {
    commit("setCustomerDividendsData", payload);
  },

  setLoadingCustomerDividends({ commit }, payload) {
    commit("setLoadingCustomerDividends", payload);
  },

  setInvestTime({ commit }, payload) {
    commit("setInvestTime", payload);
  },

  setBankAccount({ commit }, payload) {
    commit("setBankAccount", payload);
  },

  setAccountData({ commit }, payload) {
    commit("setAccountData", payload);
  },

  setSpouseData({ commit }, payload) {
    commit("setSpouseData", payload);
  },

  setContactAddressData({ commit }, payload) {
    commit("setContactAddressData", payload);
  },

  setDeclarationsData({ commit }, payload) {
    commit("setDeclarationsData", payload);
  },

  saveMutationError({ commit }, payload) {
    commit("saveMutationError", payload);
  },

  resetMutationErrors({ commit }) {
    commit("resetMutationErrors");
  },

  // Route stack actions

  addToRouteStack({ commit }, payload) {
    commit("addToRouteStack", payload);
  },

  popFromRouteStack({ commit }) {
    commit("popFromRouteStack");
  },

  cleanRouteStack({ commit }) {
    commit("cleanRouteStack");
  },

  popFromMutationErrors({ commit }) {
    commit("popFromMutationErrors");
  },

  deleteFromMutationErrors({ commit }, payload) {
    commit("deleteFromMutationErrors", payload);
  },

  setBanks({ commit }, payload) {
    commit("setBanks", payload);
  },
};

const getters = {
  currentResults: (state, getters) => {
    if (getters.fundsResults.length === 0) {
      return null;
    }

    return getters.fundsResults.reduce((total, item) => total + item.fundCurrentResult, 0);
  },

  historicalResults: (state, getters) => {
    if (getters.fundsResults.length === 0) {
      return null;
    }

    return getters.fundsResults.reduce((total, item) => total + item.fundHistoricalResult, 0);
  },

  withdrawals: (state, getters) => {
    if (getters.fundsResults.length === 0) {
      return null;
    }

    return getters.fundsResults.reduce((total, item) => total + item.fundWithdrawals, 0);
  },

  fundsResults: (state, getters) => {
    const { balances } = getters;
    if (balances.length === 0) {
      return [];
    }

    function getResultsByFund(fundSeries) {
      // Initialize the result object
      const result = {
        fundCurrentResult: 0,
        fundHistoricalResult: 0,
        fundDeposits: 0,
        fundCurrent: 0,
        fundWithdrawals: 0,
        fundDistributions: 0,
        fundCurrentDistributionReinvestments: 0,
        fundDistributionReinvestments: 0,
        fundWithdrawnResult: 0,
      };

      fundSeries.forEach(({ current = {}, aggregated = {}, redeemed = {} }) => {
        // Accumulate results
        result.fundCurrentResult += current.result?.CLP || 0;
        result.fundHistoricalResult += aggregated.result?.CLP || 0;
        result.fundDeposits += aggregated.subscriptions?.CLP || 0;
        result.fundCurrent += current.balance?.CLP || 0;
        result.fundWithdrawals += redeemed.redemptions?.CLP || 0;
        result.fundDistributionReinvestments += aggregated.reinvestedDistributions?.CLP || 0;
        result.fundCurrentDistributionReinvestments += current.reinvestedDistributions?.CLP || 0;
        result.fundDistributions += redeemed.distributions?.CLP || 0;
        result.fundWithdrawnResult += redeemed.result?.CLP || 0;
      });

      return result;
    }

    return balances.map(balance => {
      const {
        currency, symbol, fund, id, active, seriesName,
      } = balance.fundSeries;

      // Construct the quotas details object
      const fundSeriesDetails = {
        symbol,
        fundSeriesId: id,
        fundSeriesName: seriesName,
        currencyAmount: balance.current?.balance?.CLP || 0,
        quotasAmount: balance.current?.balance?.shares || 0,
        currency,
        quotaValue: balance.shareValue?.[currency] || 0,
        clpQuotaValue: balance.shareValue?.CLP || 0,
        exchangeRate: balance.exchangeRate?.USDCLP || 0,
        active,
      };

      const seriesBalancesByFund = balances.filter(balance => balance.fundSeries.fund.run === fund.run);

      // Gets all series balances for the fund and gets its results
      const fundResults = getResultsByFund(seriesBalancesByFund);
      return {
        fundShortName: fund.shortName,
        fundRun: fund.run,
        ...fundResults,
        fundSeriesDetails,
      };
    });
  },

  fundsBalances: (state, getters) => {
    if (getters.fundsResults.length === 0) {
      return {};
    }

    const fundsBalances = {};
    getters.fundsResults.forEach(fundResult => {
      const { fundRun } = fundResult;
      fundsBalances[fundRun] = fundResult;
    });

    return fundsBalances;
  },

  currency: get("currency"),

  balances: state => {
    if (!state.profileData?.balances) {
      return [];
    }

    return state.profileData.balances;
  },

  // Funds where the customer is invested. To see all funds look for unfilteredFunds
  funds: (state, getters) => {
    const { balances } = getters;
    const { fundsData } = state;

    if (!fundsData || balances.length === 0) {
      return [];
    }

    const investedFundIds = new Set(
      balances
        .filter(balance => balance.current?.subscriptions?.CLP > 0)
        .map(balance => balance.fundSeries.fund.id),
    );

    return fundsData.filter(fund => investedFundIds.has(fund.id) && fund.run !== "10207");
  },

  // Funds where the customer has no quotas
  otherFunds: (state, getters) => {
    const { balances } = getters;
    const { fundsData } = state;

    if (!fundsData || balances.length === 0) {
      return [];
    }

    const investedFundIds = new Set(
      balances
        .filter(balance => balance.current?.subscriptions?.CLP > 0)
        .map(balance => balance.fundSeries.fund.id),
    );

    return fundsData.filter(fund => !investedFundIds.has(fund.id) && fund.run !== "10207");
  },

  customerCurrent: (state, getters) => {
    const { balances } = getters;
    if (balances.length === 0) {
      return [];
    }

    return balances.map(({
      current, fundSeries, date, shareValue, exchangeRate,
    }) => {
      const {
        currency, symbol, seriesName, fund, id,
      } = fundSeries;

      return {
        ...current,
        date,
        currencyAmount: current?.balance?.CLP || 0,
        quotasAmount: current?.balance?.shares || 0,
        quotaValue: shareValue?.[currency] || 0,
        clpQuotaValue: shareValue?.CLP || 0,
        exchangeRate: exchangeRate?.USDCLP || 0,
        currency,
        fundRun: fund.run,
        symbol,
        fundSeriesName: seriesName,
        fundSeriesId: id,
      };
    }).filter(({ currencyAmount }) => currencyAmount > 0);
  },

  closeDate: (state, getters) => {
    const { customerCurrent } = getters;
    if (customerCurrent.length > 0) {
      const dates = customerCurrent.map(fundCurrent => fundCurrent?.date);
      if (dates.length > 0) return dates.reduce((a, b) => (a < b ? a : b));
    }
    // This return is met when the user had quotas in the past but now his/her total balance is at 0
    // Also met when the user is a new customer. In this case just return yesterdays date.
    return dayjs().subtract(1, "day").format("YYYY-MM-DD");
  },

  movements: state => {
    const movements = state.movementsData;

    if (!movements) {
      return [];
    }

    const MOVEMENT_PRIORITY = {
      SUBSCRIPTION: 1,
      DISTRIBUTION: 1,
      REDEMPTION: 1,
      INTERNAL_INCOMING_TRANSFER: 2,
      INTERNAL_OUTBOUND_TRANSFER: 1,
      OUTBOUND_TRANSFER: 1,
      INCOMING_TRANSFER: 2,
      CAPITAL_DISTRIBUTION: 1,
      PENDING_REDEMPTION: 3,
      DISTRIBUTION_REINVESTMENT: 2,
      REINVESTMENT_SUBSCRIPTION: 2,
      REINVESTMENT_REDEMPTION: 1,
    };
    movements.sort(
      (a, b) => MOVEMENT_PRIORITY[b.movementType] - MOVEMENT_PRIORITY[a.movementType],
    );
    movements.sort((a, b) => new Date(b.settlementDate) - new Date(a.settlementDate));
    return movements;
  },

  transactions: state => {
    const pendingMovements = [];
    if (!state.fundsData) {
      return pendingMovements;
    }
    state.fundsData.forEach(fund =>
      fund.series.forEach(singleSeries =>
        singleSeries.pendingMovements?.forEach(pendingMovement => {
          const formattedPendingMovement = pendingMovement;
          formattedPendingMovement.series = { ...singleSeries };
          formattedPendingMovement.series.fund = { ...fund };
          delete formattedPendingMovement.series.pendingMovements;
          delete formattedPendingMovement.series.fund.series;
          pendingMovements.push(formattedPendingMovement);
        }),
      ),
    );
    return pendingMovements;
  },

  profile: state => {
    if (!state.profileData) {
      return {};
    }
    return state.profileData;
  },

  clpBankAccount: state => {
    const { profileData, banks } = state;

    if (!profileData || !profileData.bankAccounts || !banks.length) return {};

    const bankAccount = profileData.bankAccounts.find(bankAccount => bankAccount?.currency === "CLP" || !bankAccount?.currency);
    if (!bankAccount) return null;

    const bankData = banks.find(
      bank => Number(bank.id) === Number(bankAccount.bank?.id),
    );

    const accountTypeData = bankSelects.data().availableBankAccountTypes.find(
      accountType => accountType.id === Number(bankAccount?.bankAccountType?.id),
    );

    return {
      accountId: bankAccount?.id,
      accountNumber: bankAccount?.accountNumber,
      bankData,
      accountTypeData,
      bankName: bankAccount.bank?.name,
      bankId: bankAccount.bank?.id,
      accountType: bankAccount?.bankAccountType?.typeName,
      accountTypeId: bankAccount?.bankAccountType?.id,
      updatedAt: bankAccount?.updatedAt,
    };
  },

  balanceHistory: state => state.balanceHistory,

  investedCustomer: (state, getters) => {
    if (state.loading) return true;
    return getters.fundsResults.some(fund => fund?.fundDeposits > 0);
  },

  previousInvestor: (state, getters) => {
    if (state.loading) return false;
    return getters.investedCustomer && getters.fundsResults.every(fund => fund?.fundCurrent === 0);
  },

  // TODO: enable when automatic bank readout is available
  // displayRecentInvestmentAlert: (state, getters) => {
  //   if (state.loading) return false;
  //   if (getters.investTime) {
  //     const dateMinutesDifference = dayjs().diff(getters.investTime, "minute");
  //     // If the difference is higher than 10 minutes, we are going to clean the invest time
  //     if (dateMinutesDifference <= 10) {
  //       return true;
  //     }
  //   }
  //   return false;
  // },

  displayRecentInvestmentAlert: () => false,

  authenticatedStatus: get("authenticatedStatus"),

  loading: get("loading"),

  loadingBalanceHistory: get("loadingBalanceHistory"),

  loadingProfile: get("loadingProfile"),

  loadingPendingMovements: get("loadingPendingMovements"),

  loadingMovements: get("loadingMovements"),

  loadingReinvestments: get("loadingReinvestments"),

  loadingCustomerDividends: get("loadingCustomerDividends"),

  loadingTransactions: getters => (
    getters.loadingPendingMovements || getters.loadingReinvestments
  ),

  hasTransactions: (state, getters) => {
    const { reinvestments, pendingMovements, loadingTransactions } = getters;

    // If the transactions are still loading, the user has no transactions
    if (loadingTransactions) return false;

    // Ensure reinvestments and pendingMovements are arrays
    const isReinvestmentsValid = Array.isArray(reinvestments);
    const isPendingMovementsValid = Array.isArray(pendingMovements);

    // Return true if there are reinvestments or pending movements
    return (
      (isReinvestmentsValid && reinvestments.length > 0) ||
    (isPendingMovementsValid && pendingMovements.length > 0)
    );
  },

  pendingMovements: get("pendingMovementsData"),

  fintocStatus: get("fintocStatus"),

  targetFund: get("targetFund"),

  referralSource: state => {
    if (!state.signup) return null;
    return state.signup.referralSource;
  },

  utmSource: get("utmSource"),

  utmContent: get("utmContent"),

  utmCampaign: get("utmCampaign"),

  redemption: get("redemptionData"),

  reinvestment: get("reinvestmentData"),

  reinvestments: get("reinvestmentsData"),

  invest: get("investData"),

  banks: get("banks"),

  signupDeclarations: state => {
    if (!state.signup) return null;
    return {
      isPep: state.signup.isPep || false,
      isCrs: state.signup.isCrs || false,
      isFatca: state.signup.isFatca || false,
      capitalOriginCountry: state.signup.capitalOriginCountry || "CHL",
    };
  },

  signupProfilingAndLaborData: (state, getters) => {
    if (!state.signup) return null;

    return {
      profilingScore: getters.profilingScore,
      selfEmployed: state.signup.selfEmployed,
      profession: state.signup.profession,
      occupation: state.signup.occupation,
      referredFrom: state.signup.referredFrom,
      netWorth: state.signup.netWorth,
      salaryRange: state.signup.salaryRange,
      capitalOrigin: state.signup.capitalOrigin,
    };
  },

  signupEmail: state => {
    const localEmail = localStorage.getItem("userEmail");
    return state.signup.email || localEmail;
  },

  signupTin: state => state.signup.taxIdentificationNumber,

  mutationErrors: get("mutationErrors"),

  latestMutationError: state => state.mutationErrors[state.mutationErrors.length - 1] || {},

  signupProfilingIdentificationError: state => {
    if (!state.mutationErrors.length === 0) return false;
    const profilingFlowMutations = ["signUpIdentification", "signUpProfiling"];

    const savedMutationErrorNames = state.mutationErrors.map(error => error.name);

    return savedMutationErrorNames.some(mutation => profilingFlowMutations.includes(mutation));
  },

  proposedEmail: state => {
    if (!state.signup) return null;
    return state.signup.proposedEmail;
  },

  signupValidationData: state => (
    {
      customerIdentifier: state.signup.rut,
      documentNumber: state.signup.documentNumber,
      front: state.signup.frontImage,
      back: state.signup.backImage,
    }),

  signupIdentificationData: state => (
    {
      name: state.signup.name,
      fatherFamilyName: state.signup.fatherFamilyName,
      motherFamilyName: state.signup.motherFamilyName,
      nacionality: state.signup.nationality,
      birthCountry: state.signup.birthCountry,
      maritalStatus: state.signup.maritalStatus,
      birthdate: state.signup.birthdate,
      gender: state.signup.gender,
      spouseName: state.signup.spouseName,
      spouseFamilyName: state.signup.spouseFamilyName,
      spouseIdentifier: state.signup.spouseIdentifier,
      conjugalRegime: state.signup.conjugalRegime,
      phone: state.signup.phone,
      street: state.signup.street,
      houseNumber: state.signup.houseNumber,
      region: state.signup.region,
      locality: state.signup.locality,
      interiorNumber: state.signup.interiorNumber,
    }),

  signupStatus: state => state.signup.status,

  reinvestDividends: state => state.signup.reinvestDividends,

  profilingScore: state => state.signup.profiling.manual ||
     state.signup.profiling.profilingAnswers.reduce((sum, a) => sum + a, 0),

  dividendDeclarationAnswer: get("dividendDeclarationAnswer"),

  customerDividends: get("customerDividendsData"),

  customerPendingDividends: (state, getters) => {
    if (state.loadingCustomerDividends || !getters.customerDividends || getters.customerDividends.length === 0) return null;

    // Filter the not answered dividends declarations
    const notAnsweredDividends = getters.customerDividends.filter(dividend => dividend.dividendAnswer === null);

    if (notAnsweredDividends.length === 0) return null;

    // Extracts the fund runs from the not answered dividends
    const fundRuns = notAnsweredDividends.map(dividend => dividend.fundSeriesDividend.fundSeries.fundRun);

    // Extracts the fund names from the fund runs using the funds mixin
    const fundNames = fundRuns.map(run => fundsMixin.methods.getFundStaticData(run).shortName);

    // Display the fund names in a single string
    function formatDividendFundNames(fundNames) {
      if (fundNames.length === 1) return fundNames[0];
      if (fundNames.length === 2) return `${fundNames[0]} y ${fundNames[1]}`;
      return `${fundNames.slice(0, -1).join(", ")} y ${fundNames[fundNames.length - 1]}`;
    }

    // Extracts the dividend declaration data
    function getDividendDeclarationData(dividends) {
      return dividends.map(dividend => ({
        fundRun: dividend.fundSeriesDividend.fundSeries.fundRun,
        dividendId: dividend?.id,
        paymentDate: dividend.fundSeriesDividend?.fundDividend?.paymentDate,
        reinvestmentReceiptDate:
          dividend.fundSeriesDividend?.fundDividend?.reinvestmentReceiptDate,
      }));
    }

    // Data to be displayed in the pending dividend declaration flow
    const pendingDividendDeclarationData = {
      dividendList: getDividendDeclarationData(notAnsweredDividends),
      fundDividendNames: formatDividendFundNames(fundNames),
    };

    return pendingDividendDeclarationData;
  },

  investTime: get("investTime"),

  signupRut: state => state.signup.rut,

  expiredOrMissingIdDocument: (state, getters) => {
    if (state.loading) return false;

    // Check if the customer profile exists
    if (getters.profile) {
    // Extract document expiration date and document number from the profile
      const { documentExpirationDate, documentNumber } = getters.profile;

      // If there is no expiration date but there is a document number, assume the ID is valid
      if (!documentExpirationDate && documentNumber) return false;

      // If there is no document number, assume the ID has expired
      if (!documentNumber) return true;

      // Calculate the days until the document expires
      const daysToExpiration = dayjs(documentExpirationDate).diff(dayjs(), "day");

      // If daysToExpiration is negative, the document has expired
      return daysToExpiration < 0;
    }
    // If no profile exists, assume the ID has not expired
    return false;
  },

  hasLaborData: (state, getters) => {
    if (state.loading) return false;
    if (getters.profile) {
      // Returns if the customer has registered selfEmployed, profession and occupation
      const { selfEmployed, profession, occupation } = getters.profile;

      return isboolean(selfEmployed) && !!profession && !!occupation;
    }
    return false;
  },

  isUnderage: (state, getters) => {
    if (state.loading) return false;

    if (getters.profile?.birthDate) {
      const currentDate = dayjs();
      return currentDate.diff(dayjs(getters.profile.birthDate), "year") < 18;
    }

    return false;
  },

  entityType: (state, getters) => {
    if (state.loading) return null;

    if (getters.profile) {
      return getters.profile.entityType;
    }

    return null;
  },

  isJuridicPerson: (state, getters) => {
    if (state.loading) return false;

    if (getters.profile) {
      return getters.profile.entityType === "J";
    }

    return false;
  },

  isPrincipalEmail: (state, getters) => {
    if (state.loading) return false;

    if (getters.profile) {
      // The email used to login
      const sessionEmail = localStorage.getItem("userEmail");

      // Checks if the list of principal emails includes the session email
      return getters.profile.emails.filter(emailItem => emailItem.principal).map(emailItem => emailItem.email).includes(sessionEmail);
    }

    return false;
  },

  customerRiskProfile: (state, getters) => {
    if (state.loading) return "loading";

    const riskProfiles = [
      { type: "low", scoreRange: [6, 8] },
      { type: "belowAverage", scoreRange: [9, 11] },
      { type: "average", scoreRange: [12, 14] },
      { type: "aboveAverage", scoreRange: [15, 17] },
      { type: "high", scoreRange: [18, 24] },
    ];

    const { profiling } = getters.profile || {};

    if (!profiling) return "none";

    const allScores = riskProfiles.flatMap(({ scoreRange }) => scoreRange);
    const minScore = Math.min(...allScores);
    const maxScore = Math.max(...allScores);

    if (profiling < minScore) return "low";
    if (profiling > maxScore) return "high";

    const profile = riskProfiles.find(
      ({ scoreRange }) => profiling >= scoreRange[0] && profiling <= scoreRange[1],
    );

    return profile ? profile.type : "none";
  },

  isSignedUp: (state, getters) => {
    if (state.loading) return false;

    if (getters.profile) {
      // If the user is signed up, the profile getter has user id
      return !!getters.profile?.id;
    }

    return false;
  },

  customerAddress: state => state.profileData.addresses[0],

  customerPhone: state => state.profileData.phones[0]?.phone,

  customerSpouse: state => state.profileData.spouses[0],

  customerTin: state => state.profileData.taxIdentificationNumbers[0],

  customerDeclarations: state => (
    {
      isPep: state.profileData.isPep,
      isCrs: state.profileData.isCrs,
      isFatca: state.profileData.isFatca,
      capitalOriginCountry: state.profileData.capitalOriginCountry,
      capitalOrigin: state.profileData.capitalOrigin,
      netWorth: state.profileData.netWorth,
      salaryRange: state.profileData.salaryRange,
      tinCountry: state.profileData.taxIdentificationNumbers[0]?.country || null,
      tinIdentification: state.profileData.taxIdentificationNumbers[0]?.identification || null,
      // reinvestDistributions: state.profileData.reinvestDistributions,
    }
  ),

  nextRouteFromStack: state => state.routeStack[state.routeStack.length - 1],

  personalDataAlertCount: (state, getters) => {
    if (state.loadingProfile) return 0;

    const {
      profile, customerSpouse, isUnderage, isJuridicPerson,
    } = getters;

    if (isUnderage || isJuridicPerson) return 0;

    // Checks if the customer is married or in a civil union
    const needsMaritalData = profile?.maritalStatus === "MARRIED" || profile?.maritalStatus === "CIVIL_UNION";

    const profileFields = [
      profile.name,
      profile.lastName,
      profile.birthDate,
      profile.maritalStatus,
      profile.occupation,
      profile.profession,
      profile.selfEmployed,
      profile.nationality,
      profile.birthCountry,
    ];

    // If the customer is married or in a civil union, add the spouse data to the profile fields
    if (needsMaritalData) {
      profileFields.push(customerSpouse?.name || null);
      profileFields.push(customerSpouse?.lastName || null);
      profileFields.push(customerSpouse?.identifier || null);
      profileFields.push(profile?.conjugalRegime || null);
    }

    // Count the number of empty fields in the customer profile
    const alertFieldsCount = profileFields.reduce((count, field) => (field === null || field === "" ? count + 1 : count), 0);

    return alertFieldsCount;
  },

  idDocumentAlertCount: (state, getters) => {
    if (state.loadingProfile) return 0;

    const { expiredOrMissingIdDocument, isUnderage, isJuridicPerson } = getters;

    if (isUnderage || isJuridicPerson) return 0;

    return expiredOrMissingIdDocument ? 1 : 0;
  },

  contactAddressAlertCount: (state, getters) => {
    if (state.loadingProfile) return 0;

    const phone = getters.customerPhone;
    const address = getters.customerAddress;

    const { isUnderage, isJuridicPerson } = getters;

    if (isUnderage || isJuridicPerson) return 0;

    if (!address) return 4;

    const alertFieldsCount = [
      phone,
      address.region,
      address.locality,
      address.streetName,
      address.houseNumber,
    ].reduce((count, field) => (field === null || field === "" ? count + 1 : count), 0);

    return alertFieldsCount;
  },

  bankingDataAlertCount: (state, getters) => {
    if (state.loadingProfile) return 0;

    const { clpBankAccount, isUnderage, isJuridicPerson } = getters;

    if (isUnderage || isJuridicPerson) return 0;

    if (!clpBankAccount) return 3;

    const alertFieldsCount = [
      clpBankAccount.accountNumber,
      clpBankAccount.bankName,
      clpBankAccount.accountType,
    ].reduce((count, field) => (field === null || field === "" ? count + 1 : count), 0);

    return alertFieldsCount;
  },

  riskProfileAlertCount: (state, getters) => {
    if (state.loadingProfile) return 0;

    const { isUnderage, isJuridicPerson } = getters;

    if (isUnderage || isJuridicPerson) return 0;

    const { profiling } = getters.profile;

    return !profiling ? 1 : 0;
  },

  documentsAlertCount: (state, getters) => {
    if (state.loadingProfile) return 0;

    const { canSignCompassContract } = getters;

    return (canSignCompassContract) ? 1 : 0;
  },

  declarationsAlertCount: (state, getters) => {
    if (state.loadingProfile) return 0;

    const { profile, isUnderage, isJuridicPerson } = getters;

    if (isUnderage || isJuridicPerson) return 0;

    const alertFieldsCount = [
      profile.capitalOrigin,
      profile.capitalOriginCountry,
      profile.isPep,
      profile.isCrs,
      profile.isFatca,
    ].reduce((count, field) => (field === null || field === "" ? count + 1 : count), 0);

    return alertFieldsCount;
  },

  transactionalProfileAlertCount: (state, getters) => {
    if (state.loadingProfile) return 0;

    const { profile, isUnderage, isJuridicPerson } = getters;

    if (isUnderage || isJuridicPerson) return 0;

    const alertFieldsCount = [
      profile.netWorth,
      profile.salaryRange,
    ].reduce((count, field) => (field === null || field === "" ? count + 1 : count), 0);

    return alertFieldsCount;
  },

  accountAlertCount: (state, getters) => {
    if (state.loadingProfile) return 0;

    const pendingGetters = [
      "personalDataAlertCount",
      "idDocumentAlertCount",
      "contactAddressAlertCount",
      "bankingDataAlertCount",
      "riskProfileAlertCount",
      "documentsAlertCount",
      "declarationsAlertCount",
      "transactionalProfileAlertCount",
    ];

    return pendingGetters.reduce((total, getter) => total + getters[getter], 0);
  },

  isMissingSignupData: (state, getters) => {
    if (state.loadingProfile) return false;

    const pendingGetters = [
      "personalDataAlertCount",
      "idDocumentAlertCount",
      "contactAddressAlertCount",
      "riskProfileAlertCount",
      "declarationsAlertCount",
    ];

    return pendingGetters.reduce((total, getter) => total + getters[getter], 0) > 0;
  },

  canSignCompassContract: (state, getters) => {
    if (state.loadingProfile) return false;

    const { completedContractCompass } = getters.signupStatus;

    const {
      isPrincipalEmail, entityType, isUnderage, isMissingSignupData,
    } = getters;

    return (
      !completedContractCompass &&
        isPrincipalEmail &&
        !isUnderage &&
        !isMissingSignupData &&
        entityType === "N"
    );
  },

  displayCompassNoticeBar: (state, getters) => {
    if (state.loadingProfile) return false;

    const { completedContractCompass } = getters.signupStatus;

    const { isPrincipalEmail, entityType, isUnderage } = getters;

    return (
      !completedContractCompass &&
        isPrincipalEmail &&
        !isUnderage &&
        entityType === "N"
    );
  },

  isMissingTransactionalProfileData: (state, getters) => {
    if (state.loadingProfile) return false;

    const { transactionalProfileAlertCount } = getters;

    return transactionalProfileAlertCount > 0;
  },

  signupCompleted: (state, getters) => {
    if (state.loading) return false;

    const { completedContract, completedContractCompass } = getters.signupStatus;

    return completedContractCompass || completedContract;
  },

  currentUser: state => {
    const { name: signupName, email: signupEmail } = state.signup;

    const localProfile = localStorage.getItem("profile");

    if (localProfile) {
      return Vue.filter("getFullName")(JSON.parse(localProfile));
    }

    return signupName || signupEmail || localStorage.getItem("userEmail");
  },

};

export default {
  actions,
  getters,
};
