<!-- eslint-disable max-len -->
<template>
  <div>
    <div class="reinvestment-wrapper reinvestment-centered-wrapper" v-if="!loading">
      <div class="reinvestment-content">
        <div class="reinvestment-step-back">
          <v-icon color="#333333" size="22" @click="$router.go(-1)">
            mdi-arrow-left
          </v-icon>
        </div>

        <h2 class="reinvestment-header">
          {{ $t("reinvestment.reinvestmentSummary.title") }}
        </h2>

        <div class="reinvestment-flex-columns">
          <div class="reinvestment-summary-column">
            <div class="summary-table-container">
              <div class="summary-table-title">
                {{ $t("reinvestment.reinvestmentSummary.table.details") }}
              </div>

              <TwoColumnsTable :tableData="detailsTableData" />
            </div>

            <div class="accept-terms-container">
              <ReusableCheckbox
                @setChecked="acceptedTerms = !acceptedTerms"
                :checked="acceptedTerms"
                :checkboxLabel="$t('reinvestment.reinvestmentSummary.accept')"
                :linkText="$t('reinvestment.reinvestmentSummary.termsAndConditions')"
                :labelLink="'https://ayuda.dvacapital.com/es/articles/5164287-terminos-y-condiciones?_gl=1*e9mvcj*_ga*MjA1NTYxOTE4Ny4xNjY4NjM4Njkz*_ga_XPHVZ3MFD2*MTY3NjMyNDYyMC40Ny4xLjE2NzYzMjQ3NTkuMC4wLjA'"
              />
            </div>
            <NewButton
              @on-click="performReinvestmentRequest()"
              :text="$t('reinvestment.reinvestmentSummary.confirmReinvestment')"
              :disabled="!acceptedTerms"
            />
            <div class="cancel-text" @click="$router.push({ name: 'home' })">
              {{ $t("base.cancel") }}
            </div>
          </div>

          <div class="reinvestment-column">
            <ReinvestmentHints />
          </div>
        </div>
      </div>
    </div>

    <div class="reinvestment-loading-container" v-else>
      <LoadingCard
        :loadingTitle="$t('reinvestment.loading.redemptionMessage')"
        :loadingText="$t('reinvestment.loading.loadingTime')"
      />
    </div>

    <ReusableDialog
      v-model="errorDialog"
      :title="$t('reinvestment.error.title')"
      :text="$t('reinvestment.error.message')"
    >
      <template v-slot:buttonRow>
        <div class="button-row">
          <div class="support-btn">
            <NewButton :text="$t('base.supportShort')" @on-click="handleSupport()" />
          </div>
          <NewButton :text="$t('base.close')" @on-click="errorDialog = false" />
        </div>
      </template>
    </ReusableDialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { customersApiFunctions } from "@/mixins/customersApiMixin.js";
import ReinvestmentHints from "@/components/reinvestments/ReinvestmentHints.vue";
import TwoColumnsTable from "@/components/shared/TwoColumnsTable.vue";
import NewButton from "@/components/shared/NewButton.vue";
import LoadingCard from "@/components/shared/LoadingCard.vue";
import ReusableDialog from "@/components/shared/ReusableDialog.vue";
import ReusableCheckbox from "@/components/shared/ReusableCheckbox.vue";
import funds from "@/mixins/funds.js";

export default {
  name: "ReinvestmentSummary",

  components: {
    TwoColumnsTable,
    ReinvestmentHints,
    NewButton,
    LoadingCard,
    ReusableDialog,
    ReusableCheckbox,
  },

  data: () => ({
    acceptedTerms: true,
    loading: false,
    errorDialog: false,
  }),

  mixins: [customersApiFunctions, funds],

  computed: {
    ...mapGetters(["reinvestment", "profile"]),

    detailsTableData() {
      const detailsHeaders = this.$t(
        "reinvestment.reinvestmentSummary.table.detailsHeaders",
      );

      return [
        {
          key: detailsHeaders.type,
          value: this.$t(
            "reinvestment.reinvestmentSummary.table.detailsValues.reinvest",
          ),
        },
        {
          key: detailsHeaders.originFund,
          value: this.$options.filters.fundShortName(
            this.reinvestment.originFund.fundRun,
          ),
        },
        {
          key: detailsHeaders.destinationFund,
          value: this.$options.filters.fundShortName(
            this.reinvestment.destinationFund.fundRun,
          ),
        },
        {
          key: detailsHeaders.amount,
          value: `$${this.$options.filters.currencyNoSymbol(
            this.reinvestment.requestedInputAmount,
          )}`,
        },
        {
          key: detailsHeaders.paymentDate,
          value: this.$t(
            `reinvestment.reinvestmentSummary.table.detailsValues.${
              this.getRedemptionPeriod(this.reinvestment.originFund.fundRun)
            }`,
          ),
        },
      ];
    },
  },

  methods: {
    getRedemptionPeriod(fundRun) {
      return this.getFundStaticData(fundRun).redemptionPeriod;
    },

    buildReinvestmentPayload() {
      return {
        requester: this.profile.emails.find(e => e.principal).email,
        requestSource: "Mi DVA",
        requestedAmount: this.reinvestment.requestedAmount,
        requestedAmountCurrency: this.reinvestment.requestedAmountCurrency,
        originFundSeriesId: this.reinvestment.originFund.fundSeriesId,
        destinationFundSeriesId: this.reinvestment.destinationFund.fundSeriesId,
      };
    },

    handleResponseErrors(error) {
      this.errorDialog = true;
      this.loading = false;
      console.error(error);
    },

    async performReinvestmentRequest() {
      this.loading = true;

      const reinvestmentPayload = this.buildReinvestmentPayload();

      try {
        const reinvestmentResponse = await this.createReinvestmentRequest(reinvestmentPayload);

        if (reinvestmentResponse?.error) {
          this.handleResponseErrors(reinvestmentResponse.errors);
        } else {
          this.$router.push({ name: "redemptionReinvestmentSuccess" });
        }
        this.loading = false;
      } catch (error) {
        this.handleResponseErrors(error);
      }
    },

    handleSupport() {
      this.$intercom.showNewMessage(this.$t("reinvestment.intercom.message"));
      this.errorDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.summary-table-container {
  background-color: #ffffff;
  width: 100%;
  border-radius: 10px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  @media (max-width: 600px) {
    padding-bottom: 0;
  }
}

.summary-table-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #333333;
  padding: 20px 30px;
  @media (max-width: 600px) {
    padding: 40px 20px 20px 20px;
  }
}

.reinvestment-flex-columns {
  gap: 0 80px;
  @media (max-width: 1280px) {
    flex-direction: column;
    gap: 60px;
  }
}

.reinvestment-summary-column {
  flex: 2;
  max-width: 600px;
}

::v-deep .full-width-table {
  tr td {
    height: 60px !important;
  }

  @media (min-width: 1264px) {
    tr td:first-child {
      width: 50% !important;
    }
  }

  @media (max-width: 600px) {
    tr {
      display: block;
      height: 80px;
    }
    tr td {
      height: 40px !important;
    }

    td.value-cell {
      float: left;
      line-height: 22px;
      padding-left: 20px;
    }
    tr td:first-child {
      width: inherit !important;
    }
  }

  .value,
  .attribute {
    font-weight: 500 !important;
    font-size: 16px;
    line-height: 160%;
    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
  .attribute {
    color: #646363;
  }
}

.accept-terms-container {
  margin-bottom: 40px;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
}
.button-row {
  display: flex;
  justify-content: space-between;
  gap: 0 40px;
  margin-top: 40px;
}

.support-btn ::v-deep .new-btn {
  background-color: #fff !important;
  border: 1px solid #000 !important;
  span {
    color: #000 !important;
  }
}

::v-deep .new-btn {
  span {
    font-size: 14px !important;
    line-height: 16px !important;
  }
}

.button-row > * {
  width: 100%;
  max-width: 190px;
}

.reinvestment-content {
  max-width: 1200px;
}
</style>
