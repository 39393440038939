<template>
  <div class="reinvestment-wrapper reinvestment-centered-wrapper">
    <div class="reinvestment-content">
      <div class="reinvestment-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>

      <div
        class="reinvesting-fund"
        v-for="(fundData, fundOrder, index) in reinvestmentFunds"
        :key="index"
      >
        <i18n
          :path="`reinvestment.chooseReinvestmentAmount.${
            fundOrder === 'originFund' ? 'moveFrom' : 'moveTo'
          }`"
          tag="p"
        >
          <template v-slot:fundName>
            <span> {{ fundData.shortName }}</span>
            <div class="inline-wrapper">
              <div
                :style="{ backgroundColor: fundData.color }"
                class="fund-icon-container center-in-page"
              >
                <v-icon color="#fff" size="12"> mdi-{{ fundData.icon }}</v-icon>
              </div>
            </div>
          </template>
        </i18n>
      </div>

      <h2 class="reinvestment-header">
        {{ $t("reinvestment.chooseReinvestmentAmount.title") }}
      </h2>

      <div class="available-balance">
        {{
          $t("reinvestment.chooseReinvestmentAmount.maxReinvestAmount", {
            fundBalance: `$${$options.filters.currencyNoSymbol(maxReinvestmentAmount)}`,
            fundName: reinvestmentFunds.originFund.shortName,
          })
        }}
      </div>

      <div class="reinvestment-flex-columns">
        <div class="reinvestment-column">
          <div class="custom-input-wrapper">
            <CustomAmountInput
              :initial-amount="0"
              :defaultValue="totalReinvestment ? maxReinvestmentAmount : null"
              :invalid="inputAmount > maxReinvestmentAmount"
              :errorHint="
                $t(
                  'reinvestment.chooseReinvestmentAmount.errorHints.maxReinvestmentAmountExceeded',
                )
              "
              :disabled="totalReinvestment"
              @on-enter="
                inputAmount < maxReinvestmentAmount && inputAmount > 0 ? confirmReinvestmentAmount() : {}
              "
              :width="$vuetify.breakpoint.xs ? '100%' : '420px'"
              @amount-ingressed="inputAmount = $event"
            />
          </div>

          <ReusableCheckbox
            class="total-reinvestment-checkbox"
            @setChecked="totalReinvestment = !totalReinvestment"
            :checked="totalReinvestment"
            :checkboxLabel="
              $t('reinvestment.chooseReinvestmentAmount.moveAllFromFund', {
                fundName: reinvestmentFunds.originFund.shortName,
              })
            "
          />

          <NewButton
            :text="$t('base.nextStep')"
            @on-click="confirmReinvestmentAmount()"
            :disabled="invalidAmount && !totalReinvestment"
          />
          <div class="cancel-text" @click="$router.push({ name: 'home' })">
            {{ $t("base.cancel") }}
          </div>
        </div>

        <div class="reinvestment-column">
          <ReinvestmentHints />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import funds from "@/mixins/funds.js";
import CustomAmountInput from "@/components/shared/CustomAmountInput.vue";
import NewButton from "@/components/shared/NewButton.vue";
import ReinvestmentHints from "@/components/reinvestments/ReinvestmentHints.vue";
import ReusableCheckbox from "@/components/shared/ReusableCheckbox.vue";

export default {
  name: "ChooseReinvestmentAmount",

  data: () => ({
    inputAmount: 0,
    totalReinvestment: false,
  }),

  mixins: [funds],

  components: {
    CustomAmountInput,
    NewButton,
    ReinvestmentHints,
    ReusableCheckbox,
  },

  computed: {
    ...mapGetters(["fundsBalances", "fundsResults", "reinvestment"]),

    reinvestmentFunds() {
      const originFund = this.getFundStaticData(this.reinvestment.originFund.fundRun);
      const destinationFund = this.getFundStaticData(
        this.reinvestment.destinationFund.fundRun,
      );
      return { originFund, destinationFund };
    },

    invalidAmount() {
      return this.inputAmount > this.maxReinvestmentAmount || this.inputAmount < 1000;
    },

    maxReinvestmentAmount() {
      return this.fundsBalances[this.reinvestment.originFund.fundRun].fundCurrent;
    },
  },

  methods: {
    ...mapActions(["setReinvestmentData"]),

    getFundReinvestmentQuotas(fundRun, reinvestmentAmount) {
      const { clpQuotaValue, quotasAmount } = this.fundsBalances[fundRun].fundSeriesDetails;

      // Calculate the proportional amount to be redeemed from this fund
      const quotasRequested = reinvestmentAmount / clpQuotaValue;

      // Format the quotas requested to 4 decimal places
      const formattedQuotasRequested = Math.round(quotasRequested * 10000) / 10000;

      const differenceFromTotal = Math.abs(formattedQuotasRequested - quotasAmount);
      //  If the user is redeeming almost the same as the total available balance, we return the total amount
      if (differenceFromTotal < 1) {
        return quotasAmount;
      }

      // Return the proportional amount in quotas
      return formattedQuotasRequested;
    },

    confirmReinvestmentAmount() {
      // The requested amount is the amount the user wants to reinvest
      const reinvestmentAmount = this.totalReinvestment ?
        this.maxReinvestmentAmount :
        this.inputAmount;

      const fundRun = Number(this.reinvestment.originFund.fundRun);
      let quotasRequested;

      if (this.totalReinvestment) {
        quotasRequested = this.fundsBalances[fundRun].fundSeriesDetails.quotasAmount;
      } else {
        quotasRequested = this.getFundReinvestmentQuotas(fundRun, reinvestmentAmount);
      }

      this.setReinvestmentData({
        ...this.reinvestment,
        requestedInputAmount: reinvestmentAmount,
        requestedAmount: quotasRequested,
        requestedAmountCurrency: "QUO",
      });

      this.$router.push({ name: "reinvestmentSummary" });
    },
  },
};
</script>

<style lang="scss" scoped>
.fund-icon-container {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.inline-wrapper {
  display: inline-block;
  margin-left: 5px;
}

.reinvestment-header {
  margin-top: 20px;
}

.reinvesting-fund {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #333333;

  p {
    margin-bottom: 5px !important;
  }
}

.available-balance {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #333333;
  margin-bottom: 40px;
}

.custom-input-wrapper {
  margin-bottom: 20px;
}

.redeem-all-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}

input[type="checkbox"] {
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #000;
  cursor: pointer;
}

.total-reinvestment-checkbox {
  margin-bottom: 40px;
}

.reinvestment-header {
  margin-bottom: 20px;
}

.cancel-text {
  text-align: right;
  color: #646363;
}

.reinvestment-content {
  max-width: 870px;
}
</style>
