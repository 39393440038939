<template>
  <div class="reinvestment-wrapper reinvestment-centered-wrapper">
    <div class="reinvestment-content">
      <div class="reinvestment-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>

      <h2 class="reinvestment-header">
        {{ $i18n.t("reinvestment.selectOriginFund.title") }}
      </h2>

      <div class="customer-funds">
        <div
          v-for="(fundBalance, index) in formattedFundsBalances"
          :key="index"
          @click="selectFund(fundBalance.fundRun)"
          class="fund-balance"
        >
          <BalanceDetailsFundCard :fundBalance="fundBalance" class="fund-balance-card" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import funds from "@/mixins/funds.js";
import BalanceDetailsFundCard from "@/components/results/BalanceDetailsFundCard.vue";

export default {
  name: "SelectOriginFund",

  components: {
    BalanceDetailsFundCard,
  },

  mixins: [funds],

  computed: {
    ...mapGetters(["fundsBalances", "reinvestment"]),

    formattedFundsBalances() {
      return Object.values(this.fundsBalances)
        .filter(fund => fund.fundCurrent > 0)
        .map(fund => ({ currencyAmount: fund.fundCurrent, fundRun: fund.fundRun }))
        .sort((a, b) => b.currencyAmount - a.currencyAmount);
    },
  },

  methods: {
    ...mapActions(["setReinvestmentData"]),

    selectFund(fundRun) {
      const { fundSeriesId } = this.fundsBalances[fundRun].fundSeriesDetails;
      this.setReinvestmentData({
        ...this.reinvestment,
        originFund: { fundSeriesId, fundRun },
      });
      this.$router.push({ name: "selectDestinationFund" });
    },
  },
};
</script>

<style lang="scss" scoped>
.fund-balance + .fund-balance {
  margin-top: 10px;
}

.fund-balance {
  max-width: 420px;
}

::v-deep .balance-details-fund-card {
  border: none !important;
}
</style>
